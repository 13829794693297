import React, { useState, useEffect } from "react"
import SearchModule from "../search2/searchModule"
import TopHeroComponent from "../herocomponents/topherocomponent"
import ContactForm from "../bodycomponents/contact-form"
import FormComponent from "../bodycomponents/form-component"
import RED_BG from "../../images/background-images/bg-red.svg"
import M_GRAY_BG from "../../images/background-images/bg-medium-gray.svg"
import { colors } from "../../utils/colors"
import { newArrivalsFaqItems, refurbishedFaqItems } from "../../utils/data/faq"
import FAQ from "../faq-components/faq"

const CatalogListing = (props) => {
  const [searchFilters, setSearchFilters] = useState({})
  const [categoryId, setCategoryId] = useState(null)
  const [categoryBlurb, setCategoryBlurb] = useState(null)
  const [NewArrivals, setNewArrivals] = useState(false)
  const [Refurbished, setRefurbished] = useState(false)

  useEffect(() => {
    import(`../../../static/categories.json`).then(
      ({ default: categoriesMap }) => {
        if (props.rootCategory === "new-arrivals") {
          setSearchFilters({ toggle: { isNewArrival: true } })
          setNewArrivals(true)
        } else if (props.rootCategory === "featured") {
          console.log("featured")
          setSearchFilters({ toggle: { isFeatured: true } })
        } else if (props.rootCategory === "refurbished") {
          setSearchFilters({
            toggle: { isRefurbished: true },
          })
          setRefurbished(true)
        } else {
          var indexPath = ""
          let pathSegments = props.location.pathname
            .replace("/catalog/", "")
            .split("/")
          var pathSegmentLookups = []
          var categoryId = null
          pathSegments.forEach((segment) => {
            indexPath = indexPath + `/${segment}`
            pathSegmentLookups.push(categoriesMap[indexPath].name)
            categoryId = categoriesMap[indexPath].sfId
            //categoryPath = categoryPath + `${categoriesMap[indexPath]} `
          })
          setCategoryId(categoryId)
          var categoryPath = pathSegmentLookups.join(" > ")
          const filter = {
            hierarchicalMenu: { "categories.lvl0": categoryPath },
            page: 1,
          }
          // console.log("filter path", categoryPath)
          setSearchFilters(filter)
        }
      }
    )
  }, [props.location.pathname])

  useEffect(() => {
    if (categoryId)
      axios
        .get(
          `/categoryLookup?sfId=${categoryId}&version=${process.env.GATSBY_DBVERSION}`
        )
        .then((response) => {
          setCategoryBlurb(response.data)
        })
        .catch((error) => {
          console.error("cannot fetch catalog info")
        })
  }, [categoryId])

  return (
    <>
      {/* New Arrivals page */}
      {NewArrivals && (
        <>
          <TopHeroComponent
            backgroundImage={RED_BG}
            backgroundColor={colors.scarletRed}
            fontShadowColor={colors.red}
            shadowColor="shadow-red-bottom"
            pageTitle="New Arrivals"
            title="Shop the Latest Equipment Arrivals"
            body={
              <p className="text-balance pt-4">
                Our inventory is continually refreshed with the latest,
                premium-quality lab equipment for a wide range of scientific
                applications. Sought-after items sell quickly after being
                listed, so explore our newest arrivals now to secure the
                highest-quality models and maximize your lab’s value.
              </p>
            }
          />
          <div className="shadow-custom-out">
            <SearchModule
              showManufacturer={true}
              searchFilters={searchFilters}
              blurb={categoryBlurb}
              isNewArrivalRoot={true}
            />
          </div>
        </>
      )}

      {/* Refurbished page */}
      {Refurbished && !NewArrivals && (
        <>
          <TopHeroComponent
            backgroundImage={RED_BG}
            backgroundColor={colors.scarletRed}
            fontShadowColor={colors.red}
            shadowColor="shadow-red-bottom"
            pageTitle="Refurbished Equipment"
            title={
              <>
                Certified Surplus:
                <br />
                High-Quality Refurbished Equipment
              </>
            }
          />
          <div className="container max-w-screen-xl py-14 shadow-custom-out">
            <div className="text-center">
              <p className="pb-8">
                At Surplus Solutions, our Certified Surplus™ refurbished
                collection offers reliable, high-quality equipment from top
                manufacturers. With flexible certification options, you can
                upgrade confidently while staying within budge.
                <br />
                Each item undergoes a rigorous inspection, repairs, and testing
                to meet or exceed OEM standards. For cost-conscious buyers, we
                provide testing options that balance quality and affordability.
                Explote our Certified Surplus Collection for dependable
                solutions, or connect with our team for expert guidance.
              </p>

              {/* Certification Options */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                {/* Refurbished Card */}
                <div className="border border-gray rounded-[4px] shadow-md hover:shadow-lg hover:scale-[102%] transform transition duration-300 flex flex-col justify-between">
                  <div>
                    <div className="bg-red text-white p-4 rounded-t-[4px]">
                      <h3 className="mb-0">Refurbished</h3>
                    </div>
                    <div>
                      <ul>
                        <li className="px-4 pt-5 pb-1">90-Day Warranty</li>
                        <li className="px-4 py-1">Tested to OEM Standards</li>
                        <li className="px-4 pt-1 pb-5">
                          Inspected & Repaired with Detailed Reporting
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-light-gray p-4 text-balance italic rounded-b-[4px]">
                    <p>Great savings with reliable quality you can count on.</p>
                  </div>
                </div>
                {/* Tested Card */}
                <div className="border border-gray rounded-[4px] shadow-md hover:shadow-lg hover:scale-[102%] transform transition duration-300 flex flex-col justify-between">
                  <div>
                    <div className="bg-red text-white p-4 rounded-t-[4px]">
                      <h3 className="mb-0">Certified</h3>
                    </div>
                    <div>
                      <ul>
                        <li className="px-4 pt-5 pb-1">30-Day Warranty</li>
                        <li className="px-4 pt-1 pb-5">
                          Tested to OEM Standards
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-light-gray p-4 text-balance italic rounded-b-[4px]">
                    <p>
                      Manage costs while getting greater assurance from your
                      equipment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-custom-in">
            <SearchModule
              showManufacturer={true}
              searchFilters={searchFilters}
              blurb={categoryBlurb}
              isNewArrivalRoot={true}
            />
          </div>
          {/* Form */}
          <span id="refurbished-equipment"></span>
          <div className="shadow-custom-out">
            <div className="container max-w-screen-xl py-14">
              <div>
                <h3 className="text-center text-balance pb-4">
                  Don't see what you need? Submit an inquiry, and we'll build it
                  for you!
                </h3>
                <div className="w-full flex flex-col-reverse md:flex-row gap-8">
                  <div className="w-full md:w-1/2">
                    <p>
                      At Surplus Solutions, our Certified Surplus™ collection
                      offers a range of certification options to give you the
                      assurance that the equipment you get—from a vast inventory
                      that includes top manufacturers—is up to the demands of
                      your process.
                    </p>
                    <p className="font-semibold py-2">
                      Choose from our categories and get solutions tailored to
                      your needs—upgrade today!
                    </p>
                    <div className="w-full flex flex-row">
                      <ul className="w-1/2 list-disc pl-5 text-wrap">
                        <li>Refrigerators</li>
                        <li>Freezers</li>
                        <li>Incubators</li>
                        <li>Centrifuges</li>
                        <li>Balances</li>
                        <li>Laminar Airflow Hoods</li>
                        <li>Stir Plate</li>
                        <li>Biosafety Cabinets</li>
                        <li>HPLC / UPLC</li>
                      </ul>
                      <ul className="w-1/2 list-disc pl-5 text-wrap">
                        <li>Gas Chromatography Mass Spec (GCMS)</li>
                        <li>Liquid Chromatography Mass Spec (LCMS)</li>
                        <li>
                          Inductively Coupled Plasma Atomic Emission
                          Spectroscopy
                        </li>
                        <li>Spectrophotometers</li>
                      </ul>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <FormComponent formType="Refurbishment" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FAQ
            title="Refurbished FAQs"
            items={refurbishedFaqItems}
            backgroundColor={colors.lightGray}
            backgroundImage={M_GRAY_BG}
            shadowColor="shadow-mediumGray-top"
          />
        </>
      )}

      {/* Rest of the pages */}
      {!NewArrivals && !Refurbished && (
        <SearchModule
          showManufacturer={true}
          searchFilters={searchFilters}
          blurb={categoryBlurb}
        />
      )}

      {!Refurbished && (
        <>
          <FAQ
            title="FAQs"
            items={newArrivalsFaqItems}
            backgroundColor={colors.lightGray}
            backgroundImage={M_GRAY_BG}
            shadowColor="shadow-mediumGray-top"
          />

          <ContactForm
            title="Sign Up for Alerts on New Arrivals"
            body={
              <p>
                Never miss out! Subscribe now to receive email updates on our
                latest lab equipment arrivals, special offers, and more.
              </p>
            }
            backgroundColor={colors.containerGray}
            fontShadowColor={colors.red}
            shadow="shadow-red-bottom"
            formType="Marketing Sign Up"
          />
        </>
      )}
    </>
  )
}

export default CatalogListing
